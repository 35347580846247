<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Order Details # {{ id }}</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/sales/orders/list/')" text="Back"/>
            </div>
        </div>
        <tabs class="b-1 round-2 of-h">
            <tab title="Information" class="p-0">
                <OrderInformationComponent :id="id"/>
            </tab>
            <tab title="Invoice" class="p-0">
                <InvoicesListingComponent :id="id"/>
            </tab>
            <tab title="Refund" class="p-0">
                <RefundsListingComponent :id="id"/>
            </tab>
            <tab title="Transaction" class="p-0">
                <TransactionsListingComponent :id="id"/>
            </tab>
        </tabs>
    </div>
</template>

<script>
import InvoicesListingComponent from './Components/InvoicesListingComponent';
import RefundsListingComponent from './Components/RefundsListingComponent';
import TransactionsListingComponent from './Components/TransactionListingComponent';
import OrderInformationComponent from './Components/OrderInformationComponent';

export default {
    name       : 'DetailsComponent',
    components : {
        OrderInformationComponent,
        TransactionsListingComponent,
        RefundsListingComponent,
        InvoicesListingComponent
    },
    data () {
        return {
            DataLoading : false,
            loading     : false,
            id          : this.$route.params.id
        };
    }
};
</script>

<style scoped>

</style>
