<template>
    <plane-vue-table :showSearchBox="false" title="Transactions" :fields="fields" :url="listUrl" :per-page="10"
                     ref="table" :page-number="false" :extra-params="{ order_id : id }">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </plane-vue-table>
</template>

<script>
import urls from '../../../../../data/urls';

export default {
    name  : 'TransactionsListingComponent',
    props : {
        id : { type : Number }
    },
    data () {
        return {
            start_date : '',
            end_date   : '',
            status     : '',
            listUrl    : urls.salesAdmin.transaction.list,
            fields     : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'order.id',
                    sortField : 'order_id',
                    title     : 'Order ID'
                },
                {
                    name      : 'transaction_mode',
                    sortField : 'transaction_mode',
                    title     : 'Transaction Mode'
                },
                {
                    name      : 'transaction_id',
                    sortField : 'transaction_id',
                    title     : 'Transaction ID'
                },
                {
                    name      : 'initiated_date',
                    sortField : 'initiated_date',
                    title     : 'Initiated Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/sales/transaction/' + rowData.id + '/details/');
        }
    }
};
</script>
