<template>
    <plane-vue-table :showSearchBox="false" title="Invoices" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                     :page-number="false" :extra-params="{ order_id : id }">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </plane-vue-table>
</template>

<script>
import urls from '../../../../../data/urls';

export default {
    name  : 'InvoicesListingComponent',
    props : {
        id : { type : Number }
    },
    data () {
        return {
            start_date : '',
            end_date   : '',
            listUrl    : urls.salesAdmin.invoices.list,
            fields     : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Created Date'
                },
                {
                    name      : 'customer',
                    sortField : 'customer',
                    title     : 'Customer'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total Amount'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        viewDetails (rowData) {
            this.$router.push('/sales/invoice/' + rowData.id + '/details/');
        }
    }
};
</script>
