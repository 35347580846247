<template>
    <plane-vue-table :showSearchBox="false" title="Refunds" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                     :page-number="false" :extra-params="{ order_id : id }">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </plane-vue-table>
</template>

<script>
import urls from '../../../../../data/urls';

export default {
    name  : 'RefundsListingComponent',
    props : {
        id : { type : Number }
    },
    data () {
        return {
            start_date    : '',
            end_date      : '',
            status        : '',
            listUrl       : urls.salesAdmin.refunds.list,
            statusOptions : [
                { value : 'Return Requested', label : 'Return Requested' },
                { value : 'Return Processing', label : 'Return Processing' },
                { value : 'Return Assigned', label : 'Return Assigned' },
                { value : 'Return Picked', label : 'Return Picked' },
                { value : 'Return Delivered', label : 'Return Delivered' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'sale_date',
                    sortField : 'sale_date',
                    title     : 'Sale Date'
                },
                {
                    name      : 'customer',
                    sortField : 'customer',
                    title     : 'Customer'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total Amount'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/sales/refund/' + rowData.id + '/details/');
        }
    }
};
</script>
