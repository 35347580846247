<template>
    <loading-animation class="m-2" v-if="DataLoading"></loading-animation>
    <div v-else>
        <div v-if="order_info" class="p-0 pb-3">
            <h5 class="p-3 bb-1 bg-1">Order Information</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Order ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.id }}</div>

                <div class="col-1 label">Order Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.sale_date }}</div>
                <div class="col-1"/>

                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.status }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1">
                    <validated-vue-select class="mb-0" name="Status" :options="statusOptions" v-model="status"/>
                </div>
                <div class="col-1 mt-2">
                    <btn size="sm" :loading="loading" loading-text="Updating.."
                         @click="changeStatus" text="Update"/>
                </div>
            </div>
        </div>
        <div v-if="account_info" class="p-0 pb-3">
            <h5 class="p-3 bb-1 bg-1">Account Information</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Customer ID</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.id }}</div>

                <div class="col-1 label">Name</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.name }}</div>
                <div class="col-1"/>

                <div class="col-2 label">Phone Number</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.phone_number }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">E-Mail</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.email }}</div>
            </div>
        </div>
        <div v-if="billing_address" class="p-0 pb-3">
            <h5 class="p-3 bb-1 bg-1">Billing Address</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Country</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.country }}</div>

                <div class="col-1 label">State</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.state }}</div>
                <div class="col-1"/>

                <div class="col-2 label">District</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.district }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">City</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.city }}</div>

                <div class="col-1 label">Pincode</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.pincode }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Address</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.address }}</div>
            </div>
        </div>
        <div v-if="shipping_address" class="p-0 pb-3">
            <h5 class="p-3 bb-1 bg-1">Shipping Address</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Country</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.country }}</div>

                <div class="col-1 label">State</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.state }}</div>
                <div class="col-1"/>

                <div class="col-2 label">District</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.district }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">City</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.city }}</div>

                <div class="col-1 label">Pincode</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.pincode }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Address</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.address }}</div>
            </div>
        </div>
        <div v-if="payment" class="p-0 pb-3">
            <h5 class="p-3 bb-1 bg-1">Payment Information</h5>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Payment Method</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.payment_method }}</div>

                <div class="col-1 label">Currency</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.payment_currency }}</div>
                <div class="col-1"/>

                <div class="col-2 label">Shipping Price</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.shipping_price }}</div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Shipping Method</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.shipping_method }}</div>
            </div>
        </div>
        <ProductsOrdered :items="products"/>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../../data/urls';
import ProductsOrdered from './ProductsOrdered';

export default {
    components : { ProductsOrdered },
    props      : {
        id : { type : Number }
    },
    name : 'OrderInformationComponent',
    data () {
        return {
            DataLoading      : false,
            loading          : false,
            order_info       : '',
            status           : '',
            statusOptions    : [],
            account_info     : '',
            billing_address  : '',
            shipping_address : '',
            payment          : '',
            products         : '',
            fields           : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Items'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                }
            ],
            details : ''
        };
    },
    mounted () {
        this.loadDetails();
        this.loadStatus();
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        async loadStatus () {
            const that = this;
            const response = await axios.form(urls.salesAdmin.orders.entryVueSelect, { order_id : that.id });
            const json = response.data;
            that.statusOptions = json.data;
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.orders.details, { entry_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.order_info = json.order_info;
                that.account_info = json.account_info;
                that.billing_address = json.address.billing_address;
                that.shipping_address = json.address.shipping_address;
                that.payment = json.payment;
                that.products = json.products;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        async changeStatus () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.orders.changeStatus, {
                entry_id : that.id,
                status   : that.status
            });
            const json = response.data;
            if (json.error === false) {
                that.order_info.status = that.status;
                that.$notify('Status Updated Successfully', 'Success', {
                    type : 'success'
                });
            } else {
                that.$notify('Failed to Update Status', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
            that.loading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
